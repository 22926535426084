<template>
    <div class="sm:pl-5 md:pr-5 mb-5 mt-5 max-w-1200 mx-auto lg:py-0 lg:px-0">
        <div class="flex justify-between pr-5 md:pr-0">
            <span class="text-base font-semibold mb-4 lg:text-2xl">Tin khuyến mãi</span>
            <nuxt-link :to="`/tin-khuyen-mai`" aria-label="Tin-khuyen-mai">
                <i class="el-icon-arrow-right font-semibold text-lg" />
            </nuxt-link>
        </div>
        <Slider
            :option="swiperOption"
        >
            <nuxt-link
                v-for="blog in blogList"
                :key="blog.id"
                :to="`/${blog.slug}`"
                class="my-2 relative bg-white border shadow-md rounded-md overflow-hidden w-[calc(100vw-40px)] sm:w-[438px] md:w-[232px] lg:w-[244px] xl:w-[288px]"
            >
                <div class="overflow-hidden p-4 sm:p-0 bg-white border-b" style="aspect-ratio: 16/9">
                    <nuxt-img
                        :src="toImage(blog.avatar|| '', '204x100')"
                        fit="cover"
                        class="w-full h-full transform rounded-lg sm:rounded-none duration-150 hover:scale-105"
                        alt="image_blog"
                        loading="lazy"
                    />
                </div>
                <div class="px-2 py-3">
                    <div class="title font-semibold text-lg leading-7 h-14 overflow-hidden hover:underline">
                        {{ blog.title }}
                    </div>
                    <div class="brief leading-7 overflow-hidden mt-2 h-36">
                        {{ blog.brief.slice(0, 157).concat('...') }}
                    </div>
                </div>
            </nuxt-link>
        </Slider>
    </div>
</template>

<script>
    import _take from 'lodash/take';
    import { image as toImage } from '~/utils/url';
    import Slider from '@/components/Slider.vue';

    export default {
        components: { Slider },
        props: {
            blogs: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                searchKey: '',
                swiperOption: {
                    isLoop: true,
                    scroll: {
                        scrollFast: 1,
                        showBottomScroll: false,
                    },
                    autoPlay: {
                        isAuto: true,
                        timeStep: 5000,
                        slideStep: 1,
                        direction: 'ltr',
                    },
                    spacing: 12,
                },
            };
        },
        computed: {
            blogList() {
                return _take(this.blogs, 10);
            },
        },
        methods: {
            toImage,
        },
    };
</script>

<style lang="sass" scoped>
// .brief
//     overflow: hidden
//     display: -webkit-box
//     text-overflow: ellipsis
//     -webkit-line-clamp: 3
//     -webkit-box-orient: vertical
</style>
